<template>
  <div class="set-bg-blank">
    <NuxtPage />
  </div>
</template>
<style scoped>

.set-bg-blank {
  background-size: 100% 100%;
  z-index: 0;
  width: 100dvw;
  height: 100dvh;
  margin: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px 15px 2dvh 15px;

  background-image: url(https://opacity.mo.cloudinary.net/small/theme/portage-background-2-blur.webp) ;
  //background-image: url(https://upbetx.s3.ap-southeast-1.amazonaws.com/images/theme/background-blur.jpg)
}

@media screen and (min-width: 600px) {
  .set-bg-blank {
    background-image: url(https://opacity.mo.cloudinary.net/small/theme/background_lobby-2.webp);
    //background-image: url(https://upbetx.s3.ap-southeast-1.amazonaws.com/images/theme/background_lobby_blur.jpg);
  }
}

</style>
